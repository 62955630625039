body {
  margin: 0;
  font-family: "Averia Sans Libre", sans-serif;
  overflow: hidden;
}
.app-layout-base {
  background-color: black;
  display: flex;
}

.page-main-wrap {
  height: 100vh;
  width: calc(100vw - 12em);
  position: relative;
}

.main-background {
  width: calc(100vw - 12em);
  height: 100vh;
}

.page-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  width: calc(100vw - 12em);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mid-element {
  height: 100vh;
  padding: 0 1em;
  background: linear-gradient(
    90deg,
    transparent,
    transparent 45%,
    black 50%,
    transparent 55%
  );
  display: flex;
  align-items: center;
}

.label-zone {
  text-align: center;
  background-color: #cacacacc;
  padding: 1em;
  font-size: 1.3em;
  border-radius: 1em;
  box-shadow: 0 0 1em 0 rgb(255 255 255 / 20%);

  img {
    height: 5em;
  }
}

.main-font-sizer {
  font-size: 1em;
}
@media only screen and (max-width: 600px) {
  .page-overlay {
    width: 100vw;
  }
  .page-main-wrap {
    width: calc(100vw);
  }
  .main-background {
    width: 100vw;
  }

  .main-font-sizer {
    font-size: 0.7em;
  }
}
