.toolbar-main-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5em;

  background-color: white;
  border-bottom: #0000003b 1px solid;
  position: relative;
  z-index: 11;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
}

.menu-toggle-button {
  display: flex;
  align-items: center;
  display: none;

  img {
    height: 2em;
  }
}

@media only screen and (max-width: 600px) {
  .menu-toggle-button {
    display: block;
  }
}

.toolbar-logo {
  img {
    height: 3em;
  }
}

.audio-button {
  cursor: pointer;
  transition: all 0.3s ease-out;
  background: transparent;
  padding: 0.4em;
  border-radius: 1em;
  img {
    transition: all 0.3s ease-out;
    height: 1em;
  }
}

.audio-button:hover {
  img {
    height: 2em;
  }
  background: rgb(204, 204, 204);
}
