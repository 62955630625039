.argument-chapter-main-wrap {
  padding: 2em;
  min-height: 25em;
}

.argument-chapter-left-zone {
  text-align: center;
  padding: 1em;
  border-radius: 2em;
  display: flex;
  gap: 1em;
  margin: 1em;
}
.argument-chapter-image {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 1em 0.5em rgba(223, 223, 223, 0.5);
  border-radius: 5em;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.character-info-photo-wrap {
  margin: 0 auto;
  position: relative;
  max-width: 70vw;
  width: 25em;
}

.argument-chapter-right-zone {
  color: white;
  padding: 0 2%;
}

.argument-chapter-content {
  font-size: 1.2em;
  line-height: 1.5em;
  text-align: justify;
}
@media only screen and (max-width: 900px) {
  .argument-chapter-main-wrap {
    flex-direction: column !important;
  }

  .argument-chapter-left-zone {
    float: none !important;
    flex-direction: column;
  }
}

.argument-chapter-quote-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.argument-chapter-quote {
  font-style: italic;
  color: #373105;
  margin-top: -4em;
  background: radial-gradient(rgb(255 255 255 / 94%) 50%, #1a2f4873);
  width: 100%;
  border-radius: 7px;
  padding: 0.2em;
}
.argument-chapter-quote-label {
  width: 20em;
  margin: 0 auto;
  font-size: 0.9em;
}

.argument-chapter-stat-box {
  color: white;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.argument-chapter-stat {
  td {
    min-width: 4em;
    overflow: hidden;
  }
}

.argument-chapter-stat-name {
  font-size: 1em;
  font-weight: bold;
  text-align: right;
  border-bottom: 1px solid white;
}
.argument-chapter-stat-value {
  font-size: 1.2em;
  border-bottom: 1px dashed white;
  position: relative;
}

.fuori-scala-gif {
  height: 2.7em;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.8;
  margin-top: -0.8em;
}

.argument-chapter-stat-value-label {
  position: relative;
  z-index: 10;
}

.argument-chapter-stat-box-outer {
  display: inline-block;
}
