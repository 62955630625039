.navigator-main-column {
  width: 12em;
  height: 100vh;
  overflow: auto;
  box-shadow: 0 0 2em rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease-out;
  z-index: 10;
  position: relative;
  img {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.navigator-item {
  z-index: 16;
  position: relative;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 1em;
  margin-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.navigator-item-img {
  height: 3em;
  width: 3em;
  border: 1px solid;
  border-radius: 50%;
  background: radial-gradient(white, transparent);
  overflow: hidden;
  img {
    height: 5.5em;
    margin: -1.2em;
    position: relative;
    width: 5.4em;
  }
}

.navigator-item:hover {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.1em;
  img {
    transform: scale(1.2);
  }
}

.mobile-menu-is-open {
  left: 0 !important;
}

.navigator-main-column-inkdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  display: none;
  transition: all 0.3s ease-out;
}

@media only screen and (max-width: 600px) {
  .navigator-main-column {
    position: absolute;
    left: -13em;
  }
  .navigator-main-column-inkdrop {
    display: block;
  }
  .mobile-menu-is-open-inkdrop {
    width: 100vw;
  }
}
