.home-page-wrap {
  overflow-y: auto;
  max-height: calc(100vh - 50px - 15em);
  padding-bottom: 15em;
  background-size: cover;
  background-position: center;
  width: calc(100vw - 12em - 300px);
}

.home-page-right-zone {
  width: 300px;
}

.home-page-outer-wrap {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .home-page-right-zone {
    display: none;
  }
  .home-page-wrap {
    width: 100%;
  }
  .home-big-block-wrap {
    flex-direction: column !important;
  }
}
