.characters-list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 50px - 15em);
  padding-bottom: 15em;
}
.page-main-title {
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  color: white;
  font-size: 2em;
  text-align: center;
  margin: 1em 0;
  background: rgba(255, 255, 255, 0.3);
}

.separator-holder {
  filter: invert(1);
  text-align: center;
  width: 30%;
  img {
    width: 90%;
    height: 4em;
    margin: -1.2em;
  }
}

.character-chapter-title {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  text-align: center;
  background: radial-gradient(red, transparent);
  width: 80%;
  margin: 3em auto;
  margin-bottom: 1em;
  border-radius: 38%;
}
