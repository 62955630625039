.home-big-block-wrap {
  display: flex;
  align-items: center;
  background: rgb(183 183 183 / 40%);
  margin: 5em 0;
  gap: 2em;
}

.home-big-block-img-wrap {
  height: 300px;
  img {
    height: 100%;
  }
}

.home-big-block-content-wrap {
  padding: 1em;
  font-size: 1.5em;
  color: white;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.home-big-block-content-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 2em;
}
.home-big-block-content-body {
  font-size: 1em;
  font-weight: normal;
}
