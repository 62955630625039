.page-main-title {
  color: white;
  font-size: 2em;
  text-align: center;
  margin: 1em 0;
  background: rgba(255, 255, 255, 0.3);
}

.separator-holder {
  filter: invert(1);
  text-align: center;
  width: 30%;
  img {
    width: 90%;
    height: 4em;
    margin: -1.2em;
  }
}

.lore-section-main-wrap {
  color: white;
}

.lore-menu-title {
  font-size: 1.2em;
}
.lore-menu-content {
  font-size: 1em;
}
.lore-menu-inner-pages {
  padding-left: 2em;
}
.lore-menu-main-wrap {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.lore-menu-banner {
  padding: 0 1em;
  background-color: rgba(255, 255, 255, 0.15);
  margin-bottom: 0.5em;
  font-size: 1em;
  transition: all 0.5s;
  border: 1px solid rgba(255, 255, 255, 0.8);
}
.lore-menu-banner:hover {
  background-color: rgba(255, 255, 255, 0.35);
  cursor: pointer;
  font-size: 1.1em;
}

.lore-menu-chevron-symbol {
  font-size: 2em;
  top: 0;
  position: relative;
}
.lore-menu-chevron-symbol-open {
  transform: rotate(90deg);
  top: 0.1em;
}
.lore-menu-chevron-wrap {
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lore-menu-title-zone {
  background-color: rgb(255 255 255 / 70%);
  padding: 0 1em;
  border-top-right-radius: 1em;
  color: black;
  display: flex;
  display: flex;
  align-items: center;
  width: fit-content;
}

.lore-page-main-wrap {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 50px - 15em);
  padding-bottom: 15em;
}

.lore-main-subpage {
  background-color: rgb(213 218 241 / 43%);
}
.lore-main-subpage-title {
  display: flex;
  align-items: center;
  gap: 1em;
  font-size: 1.5em;
  text-align: center;
  padding: 0.5em;
  background: rgba(255, 255, 255, 0.4);
  color: black;
}
.lore-main-subpage-content {
  padding: 1em;
}
.lore-main-subpage-content-text {
  background-color: #787878;
  margin-top: 0.5em;
  font-size: 1.1em;
  font-family: Arial, Helvetica, sans-serif;
}
.lore-main-subpage-content-title {
  display: flex;
  align-items: center;
  font-size: 1.3em;
  background-color: rgba(255, 255, 255, 0.45);
  padding: 0 1em;
  margin-top: 0.5em;
  cursor: pointer;
}
.lore-main-subpage-content-title:hover {
  background-color: rgba(255, 255, 255, 0.6);
}
.lore-section-main-wrap-hide {
  position: absolute;
  z-index: -100;
}

.back-label {
  font-size: 0.7em;
  font-weight: 600;
  cursor: pointer;
  color: black;
  width: -moz-fit-content;
  width: fit-content;
  height: 2em;
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
  padding: 0.2em 1em;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.back-label:hover {
  background: rgba(255, 255, 255, 0.5);
}

.lore-section-box {
  margin-top: 0.5em;
  background-color: rgb(0 0 0);
  padding: 0.5em;
  color: #b2b2b2;
  font-size: 1.3em;
}

.generic-lore-image {
  text-align: center;
  img {
    max-width: 100%;
    max-height: 700px;
  }
}
