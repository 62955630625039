.image-displayer-wrap {
  width: 20vw;
  height: 20vw;
  max-width: 200px;
  max-height: 200px;
  margin: 0 2px;
  display: inline-block;
  .display-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease-out;
  }
}
.image-displayer-wrap:hover {
  .display-pic {
    transform: scale(1.1);
  }
}

.evident-image-block {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100vw - 12em);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.8);
  img {
    max-height: 80vh;
    max-width: 90%;
  }
}

.evident-image-title {
  font-size: 1.3em;
  small {
    font-weight: 600;
    text-transform: uppercase;
  }
  background: rgba(255, 255, 255, 0.4);
  color: white;
  text-align: center;
  padding: 0.5em;
}

@media only screen and (max-width: 600px) {
  .evident-image-block {
    width: calc(100vw);
  }
  .image-displayer-wrap {
    width: 30vw;
    height: 30vw;
  }
}
