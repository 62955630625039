.gallery-main-wrap {
  height: 100vh;
}

.gallery-search-bar {
  padding: 1em;
  width: 80%;
  border-radius: 1em;
}

.gallery-search-bar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5em;
  gap: 1em;
}

.gallery-image-section {
  height: calc(100vh - 50px - 20em);
  width: 100%;
  overflow: auto;
  text-align: center;
  padding-bottom: 15em;
}

.canon-switch-button {
  background-color: #ffffff63;
  padding: 0.2em 1em;
  border-radius: 1em;
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: white;
  input,
  label {
    cursor: pointer;
  }
}

.image-displayer-wrap {
  display: inline-block;
}
